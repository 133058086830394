AFRAME.registerComponent('marker-state-animator', {
  schema: {
    markerId: {type: 'string'},    
    stateToAnimationMapping: {type: 'string'}
  },

  init: function() {
    this.mapping = JSON.parse(this.data.stateToAnimationMapping);

    if (this.el.getAttribute('animation-mixer') == null) {
      throw new Error('Expected to find "animation-mixer" on this entity.');
    }

    // A-Frame doesn't guarantee init-order. This way we know that the sensor is initialized:
    document.getElementById(this.data.markerId).addEventListener('marker-states-ready', (event) => {
      this.markerStates = event.detail.markerStates;
      this.markerStates.el.addEventListener('state-changed', (data) => this.onStateChanged(data));
    })
  },

  onStateChanged: function(data) {
    if (data.detail.current == null) {
      return;
    }

    const animationName = this.mapping[data.detail.current];

    if (animationName) {
      this.playAnimation(animationName);
    }
  },

  playAnimation: function (animationName) {
    this.el.setAttribute('animation-mixer', {
      clip: animationName,
      loop: 'repeat'
    });
  },
});